import { Link } from 'react-router-dom';

const posts = [
  {
    id: 1,
    title: 'Revolutionizing Industries with AI',
    href: '#',
    description:
      'Explore the profound impact of AI on various sectors, from healthcare to finance, manufacturing, and beyond. Discover how this transformative technology is reshaping workflows, enhancing decision-making processes, and unlocking new frontiers of innovation.',
    date: 'May 12, 2023',
    datetime: '2023-05-12',
    category: { title: 'Artificial Intelligence', href: '#' },
    author: {
      name: 'Husein Sharif',
      role: 'Software Engineer',
      href: '#',
    },
    content: `Artificial Intelligence (AI) has emerged as a transformative force across various industries, revolutionizing workflows and driving innovation. In healthcare, AI enhances diagnostics, enables personalized treatments, and assists in surgeries. The finance sector benefits from AI through improved market predictions, fraud detection, and automated customer service. Manufacturing sees increased efficiency with AI-powered predictive maintenance, automated production lines, and enhanced quality control. Retail leverages AI for personalized shopping experiences, inventory management, and customer service. In logistics and supply chain, AI optimizes routes, predicts demand patterns, and powers autonomous delivery solutions. As AI continues to evolve, its impact on these sectors and others is expected to grow, paving the way for a more efficient, connected, and intelligent world.
`

  },
  {
    id: 2,
    title: 'The Rise of Blockchain and Decentralized Applications',
    href: '#',
    description:
      'Explore the disruptive potential of blockchain technology and its applications beyond cryptocurrencies. Discover how decentralized apps (dApps) are revolutionizing various sectors, from finance to supply chain management, enabling secure and transparent peer-to-peer transactions.',
    date: 'Apr 28, 2023',
    datetime: '2023-04-28',
    category: { title: 'Blockchain', href: '#' },
    author: {
      name: 'Husein Sharif',
      role: 'Software Engineer',
      href: '#',
    },
    content: `Blockchain technology, originally developed for cryptocurrencies, is demonstrating significant disruptive potential across various industries beyond digital currencies. Its decentralized, secure, and transparent nature is transforming sectors such as finance, supply chain management, healthcare, real estate, and intellectual property rights. In finance, blockchain enables decentralized finance (DeFi) systems and smart contracts, reducing the need for intermediaries. Supply chain management benefits from improved traceability and transparency. Healthcare sees enhanced security and efficiency in managing medical records. Real estate transactions become more transparent and accessible through tokenization. Intellectual property and digital rights management are streamlined with blockchain's ability to create transparent ownership ledgers. As blockchain technology continues to evolve, its impact is expected to grow, revolutionizing how businesses and individuals interact with data and conduct transactions, ultimately leading to more efficient and transparent systems across industries.`
  },
  {
    id: 3,
    title: 'Ethical Considerations in AI Development',
    href: '#',
    description:
      'As AI continues to permeate various aspects of our lives, its crucial to address the ethical implications of this powerful technology. Explore the principles of responsible AI development, privacy concerns, algorithmic bias, and the ongoing efforts to ensure AI remains a force for good.',
    date: 'Jun 2, 2023',
    datetime: '2023-06-02',
    category: { title: 'AI Ethics', href: '#' },
    author: {
      name: 'Husein Sharif',
      role: 'Software Engineer',
      href: '#',
    },
    content: `As Artificial Intelligence (AI) becomes increasingly integrated into our daily lives, addressing its ethical challenges has become imperative. Key concerns include privacy and data security, with the need for stringent data protection measures and transparent practices. Algorithmic bias poses another significant challenge, requiring diverse and inclusive data sets and the development of explainable AI systems. Transparency and accountability are crucial, especially in high-stakes applications, necessitating the creation of frameworks that define responsibility when AI systems fail. Ongoing efforts by governments, tech companies, and researchers aim to align AI development with ethical principles such as fairness, inclusivity, and transparency. These initiatives seek to ensure AI remains a force for good, addressing global issues like climate change, healthcare accessibility, and poverty reduction. As AI continues to advance, prioritizing ethical considerations alongside technological progress is essential to shape AI into a tool that benefits all of humanity.`
  },
];


export default function Example() {
  return (
    <div className="bg-black py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-[#418FDE] font-bold text-4xl">From the blog</h2>
          <p className="mt-2 text-lg leading-8 text-gray-400">
            Learn how to grow your business with our expert advice.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-700 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-between"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-400">
                  {post.date}
                </time>
                <a
                  href={post.category.href}
                  className="relative z-10 rounded-full bg-gray-800 px-3 py-1.5 font-medium text-gray-400 hover:bg-gray-700"
                >
                  {post.category.title}
                </a>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-200 group-hover:text-gray-400">
                  <Link to={`/blog/${post.id}`}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </Link>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-400">
                  {post.description}
                </p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-200">
                    <a href={post.author.href}>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-400">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

export { posts };