import React from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';
import Nasa from '../assets/nasa.jpg';
import { FaRocket } from 'react-icons/fa';

const Hero = () => {
  return (
    <div
      className="text-white relative min-h-screen flex items-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${Nasa})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="max-w-[800px] w-full mx-auto text-center px-4">
        <p className="text-[#418FDE] font-bold p-2 text-xl mb-4">SOMALIA DIGITAL CHAMBER OF COMMERCE</p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-extrabold md:py-6 mb-8">
          Empowering Digital <span className="text-[#418FDE]">Growth</span>
        </h1>
        <div className="flex justify-center items-center mb-8">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">Driving</p>
          <Typed
            className="text-[#418FDE] md:text-4xl sm:text-3xl text-xl font-bold md:pl-4 pl-2"
            strings={['INNOVATION', 'PROGRESS', 'TECHNOLOGY']}
            typeSpeed={80}
            backSpeed={100}
            loop
          />
        </div>
        <Link 
          to="/about" 
          className="bg-[#418FDE] w-[200px] rounded-full font-medium my-6 mx-auto py-3 text-white flex items-center justify-center group hover:bg-[#3A7EC7] transition duration-300"
        >
          <FaRocket className="mr-2" />
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default Hero;
