import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaInfoCircle, FaCogs, FaEnvelope } from 'react-icons/fa';
import SDCCLogo from '../assets/SDCCLOGO.jpeg'; // Updated logo name

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center">
              <img src={SDCCLogo} alt="SDCC Logo" className="h-8 w-auto mr-2" />
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className="text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center">
              <FaHome className="mr-2" />
              Home
            </Link>
            <Link to="/about" className="text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center">
              <FaInfoCircle className="mr-2" />
              About
            </Link>
            <Link to="/services" className="text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center">
              <FaCogs className="mr-2" />
              Services
            </Link>
            <Link to="/contact" className="text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] px-3 py-2 rounded-md text-sm font-medium transition duration-300 flex items-center">
              <FaEnvelope className="mr-2" />
              Contact
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button onClick={handleClick} className="text-gray-700 hover:text-[#418FDE] focus:outline-none">
              {!nav ? <FaBars size={20} /> : <FaTimes size={20} />}
            </button>
          </div>
        </div>
      </div>
      <div className={!nav ? 'hidden' : 'md:hidden bg-white py-2'}>
        <Link to="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] flex items-center">
          <FaHome className="mr-2" />
          Home
        </Link>
        <Link to="/about" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] flex items-center">
          <FaInfoCircle className="mr-2" />
          About
        </Link>
        <Link to="/services" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] flex items-center">
          <FaCogs className="mr-2" />
          Services
        </Link>
        <Link to="/contact" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-[#418FDE] flex items-center">
          <FaEnvelope className="mr-2" />
          Contact
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
