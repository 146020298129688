import React from 'react';
import { useParams } from 'react-router-dom';

// Import the posts array from News.jsx
import { posts } from '../News';

const BlogPost = () => {
  const { id } = useParams();
  const post = posts.find(p => p.id === parseInt(id));

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <article>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{post.title}</h1>
          <div className="mt-4 flex items-center gap-x-4 text-xs">
            <time dateTime={post.datetime} className="text-gray-500">
              {post.date}
            </time>
            <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600">
              {post.category.title}
            </span>
          </div>
          <div className="mt-8 flex items-center gap-x-4">
            <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
            <div className="text-sm leading-6">
              <p className="font-semibold text-gray-900">{post.author.name}</p>
              <p className="text-gray-600">{post.author.role}</p>
            </div>
          </div>
          <div className="mt-8 prose prose-lg prose-indigo">
            <p>{post.content}</p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost;
