import React from 'react';

const Trusted = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Trusted by innovative companies across Somalia
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <div className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1">
            EXAMPLE 1
          </div>
          <div className="col-span-2 max-h-12 w-full object-contain lg:col-span-1">
            EXAMPLE 2
          </div>
          <div className="col-span-2 max-h-12 w-full object-contain lg:col-span-1">
            EXAMPLE 3
          </div>
          <div className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1">
            EXAMPLE 4
          </div>
          <div className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1">
            EXAMPLE 5
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
