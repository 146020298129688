import React from 'react';
import { FaLightbulb, FaHandshake, FaGlobe } from 'react-icons/fa';

const About = () => {
  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Somalia Digital Chamber of Commerce
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Empowering Somalia's digital future through innovation, collaboration, and growth.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaLightbulb className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Innovation</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Fostering a culture of innovation to drive Somalia's digital transformation.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaHandshake className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Collaboration</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Building partnerships to strengthen Somalia's digital ecosystem.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaGlobe className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Global Connectivity</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Connecting Somalia to the global digital economy and opportunities.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default About;