import React from 'react';
import { FaNetworkWired, FaGraduationCap, FaHandsHelping, FaBullhorn, FaChartBar } from 'react-icons/fa';

const ServicesPage = () => {
  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Our Services
          </h1>
          <p className="mt-4 text-xl text-gray-600">
            Empowering Somalia's digital sector through a range of strategic services.
          </p>
        </div>
        
        <div className="mt-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaNetworkWired className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Networking Events</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We organize regular networking events to connect digital businesses, entrepreneurs, and investors, fostering collaboration and partnerships.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaGraduationCap className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Training and Workshops</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We provide training programs and workshops to enhance digital skills and knowledge among Somalia's workforce and entrepreneurs.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaHandsHelping className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Business Support</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We offer guidance and support to digital startups and businesses, helping them navigate challenges and seize opportunities in the digital economy.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaBullhorn className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Advocacy</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We advocate for policies and regulations that support the growth of Somalia's digital sector, representing the interests of our members to government and international bodies.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaChartBar className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Market Research</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We conduct and share market research to provide valuable insights into Somalia's digital landscape, helping businesses make informed decisions.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
