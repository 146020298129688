import React from 'react';
import { FaLightbulb, FaHandshake, FaGlobe, FaChartLine, FaUniversity } from 'react-icons/fa';

const AboutPage = () => {
  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            About Somalia Digital Chamber of Commerce
          </h1>
          <p className="mt-4 text-xl text-gray-600">
            Empowering Somalia's digital future through innovation, collaboration, and growth.
          </p>
        </div>
        
        <div className="mt-20">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Our Mission</h2>
          <p className="text-lg text-gray-700 mb-12">
            The Somalia Digital Chamber of Commerce is dedicated to fostering the growth and development of Somalia's digital economy. We aim to create a vibrant ecosystem that supports innovation, encourages collaboration, and drives economic progress through technology.
          </p>
          
          <h2 className="text-3xl font-bold text-gray-900 mb-8">What We Do</h2>
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaLightbulb className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Foster Innovation</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We promote and support innovative digital solutions that address local challenges and create new opportunities for growth.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaHandshake className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Facilitate Collaboration</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We bring together businesses, government entities, and educational institutions to create a strong, collaborative digital ecosystem.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaGlobe className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Promote Global Connectivity</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We work to connect Somalia's digital sector with global markets, fostering international partnerships and investment opportunities.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaChartLine className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Drive Economic Growth</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We advocate for policies and initiatives that support the growth of Somalia's digital economy and create new job opportunities.
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#418FDE] text-white">
                  <FaUniversity className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Promote Digital Education</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                We support initiatives to improve digital literacy and skills development, preparing Somalia's workforce for the digital future.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
